<template>
  <AbstractColumn :header="header">
    <IconCheck v-if="check" class="check-icon" />
  </AbstractColumn>
</template>

<script>
import IconCheck from '@/assets/img/svg/check.svg?inline'
import AbstractColumn from '@/components/table/columns/AbstractColumn'

export default {
  name: 'CheckColumn',
  props: {
    header: {
      type: String,
      required: true
    },
    check: {
      type: Boolean,
      required: true
    }
  },
  components: {
    AbstractColumn,
    IconCheck
  }
}
</script>

<style scoped lang="scss">
.check-icon {
  @include size(18px);

  path:first-of-type {
    fill: #5AB963;
  }
  path:nth-of-type(2n){
    fill: #ffffff;
  }
}
</style>
