<template>
  <section>
    <QuizFilter />
    <Preloader v-if="callingAPI" />
    <QuizTable />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import QuizFilter from '@/components/quiz/vlm/QuizFilterVlm'
import QuizTable from '@/components/table/QuizTableVlm'

export default {
  name: 'QuizListViewVlm',
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  components: {
    QuizTable,
    Preloader,
    QuizFilter
  }
}
</script>
