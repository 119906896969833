<template>
  <AbstractFilter
    :on-filter="filterQuizzes"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="quiz_new"
    filter-panel-class="filter-panel-quiz-vlm"
  >
    <Select
      v-model="filter.site"
      :options="sites"
      :label="$t('quizVlm.filter.server')"
      id="filter_site"
    />
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('quizVlm.filter.title')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('quizVlm.filter.id')"
    />
  </AbstractFilter>
</template>
<script>
import Input from '@/components/form/inputs/Input'
import QuizFilterModel from '@/model/QuizFilterVlm'
import AbstractFilter from '@/components/filter/AbstractFilter'
import Select from '@/components/form/select/Select'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'QuizFilterVlm',
  data () {
    return {
      filter: this._.cloneDeep(QuizFilterModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.QUIZ_PERMISSIONS
    }
  },
  components: {
    AbstractFilter,
    Input,
    Select
  },
  computed: {
    sites () {
      return this.$store.getters['site/all']
    }
  },
  methods: {
    filterQuizzes () {
      this.$store.commit('quizVlm/setPage', 1)
      this.$store.commit('quizVlm/setFilter', this.filter)
      this.$store.dispatch('quizVlm/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(QuizFilterModel)
      this.$store.commit('quizVlm/setFilter', this.filter)
      this.$store.dispatch('quizVlm/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['quizVlm/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-quiz-vlm {
  @include bp(7) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
