<template>
  <TitleWithSubtitleColumn
    :title-header="getTitleHeader"
    :title-value="createdAt ? $options.filters.prettyDateTime(createdAt) : ''"
    :subtitle-header="getSubtitleHeader"
    :subtitle-value="createdBy ? getUserNameById(createdBy) : ''"
  />
</template>

<script>
import UserService from '@/services/user/UserService'
import TitleWithSubtitleColumn from '@/components/table/columns/TitleWithSubtitleColumn'

export default {
  name: 'CreatedByAndAtColumn',
  components: { TitleWithSubtitleColumn },
  props: {
    createdAt: {
      type: String,
      default: null
    },
    createdBy: {
      type: Number,
      default: null
    },
    titleHeader: {
      type: [String, undefined],
      default: undefined
    },
    subtitleHeader: {
      type: [String, undefined],
      default: undefined
    }
  },
  computed: {
    getTitleHeader () {
      return this.titleHeader ?? this.$t('system.created_at')
    },
    getSubtitleHeader () {
      return this.subtitleHeader ?? this.$t('system.created_by')
    }
  },
  methods: {
    getUserNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    }
  }
}
</script>
